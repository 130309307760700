.App {
  text-align: center;
}

.App-logo {
  
  pointer-events: none;
  height:50px
}

.logo-style{
  position: fixed;
  left: 0px;
  top: 20px;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*font-size: calc(10px + 2vmin);*/
  font-size: calc(2px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav-container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navbar {
  height: 60px;
  background-color: transparent;
  position: relative;
}

.nav-elements {
  position: fixed;
  right: 0;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 10px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: #2f234f;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

#logout-button {
  background-color: #2196F3;
  color: white;
  border: none;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 400;
}

#logout-button[disabled] {
  background-color: lightgray;
  color: gray;
}

#logout-button:hover {
  background-color: #1565c0; /* Darker blue, similar to enel.com */
}

#user-name {
  color: white;
  border: none;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 400;
}



