/* SuccessPage.css */

/* Center the content vertically and horizontally */
.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }
  .paragraphTitle{
    color:#2196F3
  }
  
  /* Style the container and message */
  .message-container {
    text-align: left;
    background-color: #f3f4f6;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    color:black;
  }
  
  /* Style the JSON data box */
  .json-data {
    margin-top: 20px;
    background-color: #ffffff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color:black;
  }

  .extracted_data {
    vertical-align: top;
  }

  .upload-area {
    border: 2px dashed #2196F3;
    padding: 20px;
    border-radius: 5px;
    text-align: left;
    cursor: pointer;
  }
  
  .upload-area:hover {
    background-color: #f0f8ff; /* Light blue, similar to enel.com */
  }
  
  .upload-area label {
    color: #2196F3;
    font-weight: bold;
    cursor: pointer;
  }

  .text-red-600{
    padding: 20px;
    background-color: green;
  }

  .input-mail {
    background-color: #a1c8e9;
    color: #fff;
    font-weight: bold;
    padding: 2px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    min-width: 160px;
    --min-width: 300px;
    min-height: 36px;
  }

  #category-label{
    font-weight: bold;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0 12px;
    color: #999;
    pointer-events: none;

  }

  #float-label {
    display: flex;
    flex-direction: column;
    min-width: 200px;
    position: relative;

    transform-origin: top left;
    transition: all 0.2s ease-out;
  }

  #float-label:focus-within label {
    transform: translate(0, 1px) scale(0.75);
  }

  #float-label label {
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0 12px;
    color: #999;
    pointer-events: none;
    position: absolute;
    transform: translate(0, 12px) scale(1);
  }

  #float-label .Active {
    transform: translate(0, 1px) scale(0.75);
  }

  .mail-status{
    text-align: center;
    color:#f0f8ff;
  }

  .pasi-reg{
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
  }

  .container_reg {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    word-wrap: break-word;
  }

  .container_reg_resp {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    word-wrap: break-word;
  }
  