/* src/FileUpload.css */
body {
    font-family: 'Arial', sans-serif;
    background-color: #58c0e8;
    /*background-image: url("./ppc.jpg");
    background-repeat: no-repeat, no-repeat;
    background-position: 0 0;
    background-size: cover;*/

    /* top: 15%; */

  }
  .text-red-600{
    padding: 20px;
    background-color: green;
  }
  .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }
  
  .upload-area {
    border: 2px dashed #2196F3;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
  }
  
  .upload-area:hover {
    background-color: #f0f8ff; /* Light blue, similar to enel.com */
  }
  
  .upload-area label {
    color: #2196F3;
    font-weight: bold;
    cursor: pointer;
  }

  .upload-area input {
    padding-left: 10px;
  }
  
  .selected-files {
    margin-top: 20px;
  }
  
  .selected-files ul {
    list-style: none;
    padding: 0;
  }
  
  .selected-files li {
    margin: 5px 0;
    color: #333;
  }
  
  .upload-button {
    background-color: #2196F3;
    color: #fff;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    min-width: 200px;
    min-height: 36px;
  }

  .upload-button:disabled {
    background: #e7e8e9;
    color: #9fa3a9;
    cursor: not-allowed;
  }
  
  .upload-button:hover {
    background-color: #1565c0; /* Darker blue, similar to enel.com */
  }

  .spinner {
    animation-name: spin;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .text-blue-600{
    padding: 20px;
    background-color: #2196F3;
  }
  
/*  li{
    display: inline-block;
  }*/